import React from "react";
import styled from "styled-components";

const View = styled.div`
  flex: 1;
`;
const Head = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 14px;
  margin-top: 30px;
`;
const Body = styled.span`
  line-height: 28px;
  font-size: 16px;
  text-align: justify;
  letter-spacing: 0.8px;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <View>
    <Body>
      SEQRET 서비스와 관련하여, 번인은 동의 내용을 숙지하였으며, 이에따라 본인의
      개인정보를 아래의 목적으로 (주)시큐릿이 수집 및 이용하고, 본인에게 전자적
      전송매체를 통하여, 본인의 서비스 내용 및 이벤트 관련 알림 및 마케팅 등의
      목적으로 개인에게 최적화된 광고 또는 정보를 다음과 같이 전송 하는것에 동의
      합니다.
    </Body>
    <br />
    <br />
    <Head>구분</Head>
    <Body>
      수집 이용 항목
      <br />
      이용목적
      <br />
      선택항목
      <br />
      이동전화 번호, 푸시ID, 동의 경로/일시
      <br />
      1) 본인의 서비스 이용 관련 알림 및 개발 및 개선, 문의 등에 대한 개인정보
      제공
      <br />
      2) 신규 서비스나 이벤트, 제3자의 상품 또는 “모두의 SEQRET” 관련 광고 정보
      전송
    </Body>
    <Head>1. 보유 및 이용기간</Head>
    <Body>
      -별도 동의 철회 시 또는 동의일로부터 1년 10개월까지
      <br />
      -단 관련 법령의 규정에 따라 보존할 필요가 있을 경우,해당기간까지 보존함
    </Body>
    <Head>2. 알림 및 광고 수신동의 철회</Head>
    <Body>
      -SEQRET 앱 내 서비스 알림 및 광고 수신동의 약관철회
      <br />
      -SEQRET 고객센터 : <br />
      *본 동의는 "SEQRET“의 선택적 기능 제공을 위한 개인정보 수집/이용에 대한
      동의로서, 이에 대한 동의가 없을 경우에도 SEQRET서비스 이용이 가능합니다.
      <br />
      ※법령에 따른 개인정보의 수집/이용, 계약의 이행/편의 증진을 위한 개인정보
      처리위탁 및 개인정보 처리와 관련된 일반 사항은 “SEQRET”의 개인정보
      처리방침에 따릅니다.
      <br />
      ※“ SEQRET”에서는 고객이 수집 및 이용에 동의한 개인정보를 활용하여, 전자적
      전송 매체(SMS/MMS/E-mail/App Push 등 다양한 전송 매체)를 통하여, 주식회사
      선배들 및 제 3자의 상품 또는 서비스에 대한 개인 맞춤형 광고 정보를 전송할
      수 있습니다.
      <br />
      ※고객이 본 수신 동의를 철회하고자 할 경우 고객센터를 통하여 수신동의 철회
      요청을 할 수 있습니다.
      <br />
      (시행일) 본 약관은 2023년 3월 1일부터 시행합니다.
      <br />
    </Body>
  </View>
);
