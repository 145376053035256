import React from "react";
import styled from "styled-components";

const View = styled.div`
  flex: 1;
`;
const Head = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 14px;
  margin-top: 30px;
`;
const Body = styled.span`
  line-height: 28px;
  font-size: 16px;
  text-align: justify;
  letter-spacing: 0.8px;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <View>
    <Head>제 1조 목적</Head>
    <Body>
      본 이용약관은 “SEQRET”(이하 사이트)의 서비스의 이용조건과 운영에 관한 제반
      사항을 규정을 목적으로 합니다.
    </Body>
    <Head>제 2조 용어의 정의</Head>
    <Body>
      본 약관에서는 사용되는 주요한 용어의 정의는 다음과 같습니다. <br />
      <br />
      ① 회원 : 어플리케이션(이하 “앱”)의 약관에 동의하고 개인정보를 제공하여
      회원등록을 한 자로서, 앱과의 이용 계약을 체결하고 앱을 이용하는 이용자를
      말합니다. <br />
      ② 이용계약 : 앱 이용과 관련하여 앱과 회원간에 체결하는 계약을 말합니다.
      <br />
      ③ 회원 아이디(이하 ID) : 회원의 식별과 회원의 서비스 이용을 위하여 앱 가입
      전부터 회원별로 보유하고 있는 고유한 전화번호 중 회원이 앱 내에서 ID로
      활용하고자 입력한 전화번호를 말합니다.
      <br />
      ④ 비밀번호 : 회원이 앱 가입시 입력한 ID와 일치된 회원임을 확인하고 회원의
      권익 보호를 위하여 회원이 선정한 문자와 숫자의 조합을 말합니다.
      <br />
      ⑤ 운영자 : 앱 페이지를 개설하여 운영하는 운영자를 말합니다.
      <br />
      ⑥ 해지 : 회원이 이용계약을 해약하는 것을 말합니다. (아래 4조 4항 5항
      내용을 참고 하시기 바랍니다.)
      <br />⑦ 프리미엄 유료 구매(이하 “구독자”) : 프리미엄 회원권을 구매 한 자를
      말합니다.
    </Body>
    <Head>제 3조 약관 외 준칙</Head>
    <Body>
      운영자는 필요한 경우 별도로 운영정책을 공지 안내할 수 있으며, 본 약관과
      운영정책이 중첩 될 경우 운영정책이 우선 적용 됩니다.
    </Body>
    <Head>제 4조 이용계약 체결 및 해지</Head>
    <Body>
      ① 이용계약은 회원으로 등록하여 앱을 이용하려는 자의 본 약관 내용에 대한
      동의와 가입신청에 대하여 운영자의 이용승낙으로 성립합니다. <br />
      ② 회원으로 등록하여 서비스를 이용하려는 자는 앱 가입신청 시 본 약관을 읽고
      아래에 있는 동의합니다 를 선택하는 것으로 본 약관에 대한 동의 의사 표시를
      합니다.
      <br />
      ③ 구독자로 등록하여 서비스를 이용하려는 자는 앱 프리미엄 구매를 하여야
      구독자로서 이용이 가능합니다.
      <br />
      ④ 해지. 회원은 언제라도 SEQRET 구독자를 해지 할 수 있으며, 이 경우 결제
      주기가 종료 될 때 까지는 서비스 이용이 가능합니다. 본 약관 또는 과실을
      이유로 한 환불 의무를 제외하고, 멤버십을 이용하지 않은 SEQRET 서비스에
      대한 환불은 제공 되지 않습니다. 구독자 해지를 하려면 ‘고객센터‘에 문의하여
      안내를 따르시기를 바랍니다. 구독자를 해지하는 경우, 계정은 현재 결제
      주기가 종료될 때 자동으로 종료 됩니다.
      <br />⑤ 상품 (SEQRET QR 안전주차번호판 이하 “상품” 이라고 합니다.) 환불은
      상품 미개봉 및 QR 미인식 일 경우 3일 이내 환불 배송비는 구매자가
      부담한다는 조건으로 환불이 가능 합니다.
    </Body>
    <Head>제 5조 서비스 이용 신청</Head>
    <Body>
      ① 회원으로 등록하여 앱을 이용하려는 이용자는 앱에서 요청하는
      제반정보(이용자 ID, 비밀번호, 닉네임 등)를 제공해야 합니다.
      <br />② 타인의 정보를 도용하거나 허위의 정보를 등록하는 등 본인의 진정한
      정보를 등록하지 않은 회원은 앱 이용과 관련하여 아무런 권리를 주장 할 수
      없으며, 관계 법령에 따라 처벌 받을 수 있습니다.
    </Body>
    <Head>제 6조 개인정보처리방침</Head>
    <Body>
      앱 및 운영자는 회원가입 시 제공한 개인정보 중 비밀번호를 가지고 있지
      않으며 이와 관련된 부분은 앱의 개인정보처리방침을 따릅니다.
      <br />
      운영자는 관계 법령이 정하는 바에 따라 회원등록정보를 포함한 회원의
      개인정보를 보호하기 위하여 노력합니다.
      <br />
      회원의 개인정보보호에 관하여 관계법령 및 앱이 정하는 개인정보처리방침에
      정한 바에 따릅니다.
      <br />
      <br />
      단, 회원의 귀책 사유로 인해 노출된 정보에 대해 운영자는 일체의 책임을 지지
      않습니다.
      <br />
      운영자는 회원이 미풍양속에 저해되거나 국가안보에 위배되는 게시물 등 위법한
      게시물을 등록 • 배포할 경우 관련 기관의 요청이 있을 시 회원의 자료를 열람
      및 해당 자료를 관련 기관에 제출할 수 있습니다.
    </Body>
    <Head>제 7조 운영자의 의무</Head>
    <Body>
      ① 운영자는 이용회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할
      경우에는 가급적 빨리 처리하여야 합니다.
      <br />
      다만, 개인적인 사정으로 신속한 처리가 곤란한 경우에는 사후에 공지 또는
      이용회원에게 전자우편 등을 보내는 등 최선을 다 합니다.
      <br />② 운영자는 계속적이고 안정적인 앱 제공을 위하여 설비에 장애가
      생기거나 유실된 때에는 이를 지체없이 수리 또는 복구 할 수 있도록 앱을 운영
      해야 합니다. 다만, 천재지변 또는 앱이나 운영자에게 부득이한 사유가 잇는
      경우, 앱 운영을 일시 정지 할 수 있습니다.
    </Body>
    <Head>제 8조 회원의 의무</Head>
    <Body>
      ① 회원은 본 약관에서 규정하는 사항과 운영자가 정한 제반 규정, 공지사항 및
      운영정책 등 앱이 공지하는 사항 및 관계 법령을 준수하여야 하며, 기타 앱의
      업무에 방해가 되는 행위, 앱의 명예를 손상하는 행위를 해서는 안됩니다.
      <br />
      ② 회원은 앱의 명시적 동의가 없는 한 서비스의 이용 권한, 기타 이용계약상
      지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
      <br />
      회원은 상품(시큐릿 QR안심 전화번호카드)의 QR을 운영자의 동의 없이 무단으로
      복제,공유 등을 하여서는 아니되며, 무단 복제, 공유 하여 이용 시 법적 조치
      및 환불 없이 이용 계약을 해지 하거나 서비스를 중지 할 수 있습니다.
      <br />
      ③ 이용고객은 아이디 및 비밀번호 관리에 상당한 주의를 기울여야 하며,
      운영자나 앱의 동의 없이 제3자에게 아이디를 제공하여 이용 할 수 없습니다.
      <br />
      ④ 회원은 운영자와 앱 및 제3자의 지적 재산권을 침해해서는 안됩니다.
      <br />
    </Body>
    <Head>제 9조 서비스 이용 시간</Head>
    <Body>
      ① 서비스 이용 시간은 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일
      24시간을 원칙으로 합니다. 단, 앱의 시스템 정기점검, 증설 및 교체를 위해
      앱이 정한 날이나 시간에 서비스를 일시중단 할 수 있으며 예정된 작업으로
      인한 서비스 일시 중단은 앱 페이지에서 사전에 공지 하오니 수시로 참고하시길
      바랍니다.
      <br />
      ② 단, 앱은 다음 경우에 대하여 사전 공지나 예고 없이 서비스를 일시적 혹은
      영구적으로 중단할 수 있습니다.
      <br />
      -긴급한 시스템 점검,증설,교체,고장 혹은 오동작을 일으키는 경우
      <br />
      - 국가 비상사태,정전,천재지변 등의 불가항력적인 사유가 있는 경우
      <br />
      -전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우
      <br />
      - 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
      <br />③ 전항에 의한 서비스 중단의 경우 앱은 사전에 공지사항 등을 통하여
      회원에게 통지 합니다. 단, 앱이 통제할 수 없는 사유로 발생한 서비스의
      중단에 대하여 사전공지가 불가능한 경우에는 사후공지로 대신 합니다.
    </Body>
    <Head>제 10조 서비스 이용해지</Head>
    <Body>
      ① 회원이 앱과의 이용계약을 해지하고자 하는 경우에는 회원 본인이 앱 탈퇴
      페이지를 통하여 등록해지 신청을 하여야 합니다. 한편, 앱 이용 해지와 별개로
      앱에 대한 이용계약 해지, 구독해지는 별도로 하여야 합니다.
      <br />② 해지 신청과 동시에 앱이 제공하는 앱 서비스 관련 프로그램이 회원
      관리 화면에서 자동적으로 삭제됨으로 운영자는 더 이상 해지신청자의 정보를
      볼 수 없습니다.
    </Body>
    <Head>제 11조 서비스 이용 제한</Head>
    <Body>
      회원은 다음 각호에 해당하는 행위를 하여서는 아니 되며 해당 행위를 한
      경우에 앱은 회원의 서비스 이용제한 및 적법한 조치를 할 수 있으며
      이용계약을 해지하거나 기간을 정하여 서비스를 중지할 수 있습니다.
      <br />
      ① 회원 가입시 혹은 가입 후 정보 변경 시 허위내용을 등록하는 행위
      <br />
      ② 타인의 앱 이용을 방해하거나 정보를 도용하는 행위
      <br />
      ③ 앱의 운영진, 직원 또는 관계자를 사칭하는 행위
      <br />
      ④ 앱, 기타 제 3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는
      행위
      <br />
      ⑤ 다른 회원의 ID를 부정하게 사용 하는 행위
      <br />
      ⑥ 다른 회원에 대한 개인정보를 그 동의 없이 수집,저장,공개하는 행위
      <br />
      ⑦ 범죄와 결부 된다고 객관적으로 판단되는 행위
      <br />⑧ 기타 관련 법령에 위배되는 행위
    </Body>
    <Head>제 12조 게시물의 관리 (12~14조는 추후 서비스에 적용)</Head>
    <Body>
      ① 앱의 게시물과 자료의 관리 및 운영의 책임은 운영자에게 있습니다. 운영자는
      항상 불량 게시물 및 자료에 대하여 모니터링을 하여야 하며, 불량 게시물 및
      자료를 발견하거나 신고를 받으면 해당 게시물 및 자료를 삭제하고 주의를
      기울여야 합니다. 한편, 이용 회원이 올린 게시물에 대해서는 게시자 본인에게
      책임이 있으니 회원 스스로 본 이용약관에서 위배되는 게시물은 게재해서는
      안됩니다.
      <br />
      ② 정보통신윤리위원회 등 공공기관의 시정요구가 있는 경우 운영자는 회원의
      사전동의 없이 게시물을 삭제하거나 이동 할 수 있습니다.
      <br />
      ③ 불량게시물의 판단 기준은 다음과 같습니다.
      <br />
      -다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상하는 내용인 경우
      <br />
      -공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크 시키는 경우
      <br />
      -영리를 목적으로 하는 광고일 경우
      <br />
      -범죄와 결부 된다고 객관적으로 인정되는 내용일 경우
      <br />
      -다른 이용자 또는 제 3자와 저작권 등 기타 권리를 침해하는 경우
      <br />
      -기타 관계 법령에 위배된다고 판단되는 경우
      <br />④ 앱 및 운영자는 게시물 등에 대하여 제 3자로부터 명예 훼손,
      지적재산권 등의 권리 침해를 이유로 게시중단 요청을 받은 경우 이를 임시로
      게시 중단(전송중단)할 수 있으며, 게시중단 요청자와 게시물 등록자 간에
      소송, 합의 기타 이에 준하는 관련 기관의 결정 등이 이루어져 앱에 접수된
      경우 이에 따릅니다.
    </Body>
    <Head>제 13조 게시물의 보관</Head>
    <Body>
      사이트 운영자가 불가피한 사정으로 본 사이트를 중단하게 될 경우, 회원에게
      사전 공지를 하고 게시불의 이전이 쉽도록 모든 조치를 하기 위해 노력합니다.
    </Body>
    <Head>제 14조 게시물에 대한 저작권</Head>
    <Body>
      ①회원이 앱 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 또한
      앱은 게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다. 다만
      비영리 목적인 경우는 그러하지 아니하며, 또한 서비스 내의 게재권을
      갖습니다.
      <br />
      ② 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등
      서비스에 게재된 자료를 상업적으로 사용 할 수 없습니다.
      <br />③ 운영자는 회원이 게시하거나 등록하는 사이트 내의 내용물, 게시
      내용에 대해 제 12조 각호에 해당한다고 판단되는 경우 사전통지 없이
      삭제하거나 이동 또는 등록 거부 할 수 있습니다.
    </Body>
    <Head>제 15조 손해배상</Head>
    <Body>
      ① 본 앱에서 발생한 모든 민,형법상 책임은 회원 본인에게 1차적으로 있습니다.
      <br />
      ② 본 앱으로부터 회원이 받은 손해가 천재지변 등 불가항력적이거나 회원의
      고의 또는 과실로 인하여 발생한 때에는 손해배상을 하지 않습니다.
      <br />③ 부득이한 사정으로 인해 본 앱의 사용이 불가능해질 경우 구독자에게
      손해 배상을 합니다. 단, 구독 기간 12개월 이내의 구독자에게만
      손해배상금(프리미엄 유료 구매 금액)을 지급합니다.
    </Body>
    <Head>제 16조 면책</Head>
    <Body>
      ① 운영자는 회원이 앱의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나
      서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는
      책임이 면제 됩니다.
      <br />
      ② 운영자는 본 앱의 서비스 기반 및 타 통신업자가 제공하는 전기통신 서비스의
      장애로 인한 경우에는 책임이 면제되며 본 앱의 서비스 기반과 관련되어 발생한
      손해에 대해서는 앱의 이용약관에 준합니다.
      <br />
      ③ 운영자는 회원이 저장, 게시 또는 전송한 자료와 관련하여 일체의 책임을지지
      않습니다.
      <br />
      ④ 운영자는 회원의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는
      책임지지 아니합니다.
      <br />
      ⑤ 운영자는 회원 상호 간 또는 회원과 제 3자 상호 간, 기타 회원의 본 서비스
      내외를 불문한 일체의 활동(데이터전송, 기타 커뮤니티 활동 포함)에 대하여
      책임을지지 않습니다.
      <br />
      ⑥ 운영자는 회원이 게시 또는 전송한 자료 및 본 앱으로 회원이 제공받을 수
      있는 모든 자료들의 진위,신뢰도,정확성 등 그 내용에 대해서는 책임지지
      아니합니다.
      <br />
      ⑦ 운영자는 회원 상호간 또는 회원과 제 3자 상호간에 서비스를 매개로 하여
      물품거래 등을 한 경우에 그로부터 발생하는 일체의 손해에 대하여 책임지지
      아니합니다.
      <br />
      ⑧ 운영자는 운영자의 귀책 사유 없이 회원간 또는 회원과 제 3자간에 발생한
      일체의 분쟁에 대하여 책임지지 아니합니다.
      <br />⑨ 운영자는 서버 등 설비의 관리,점검,보수,교체 과정 또는 소프트웨어의
      운용 과정에서 고의 또는 고의에 준하는 중대한 과실 없이 발생 할 수 있는
      시스템의 장애, 제 3자의 공격으로 인한 시스템의 장애,국내외의 저명한
      연구기관이나 보안 관련 업체의 의해 대응방법이 개발되지 아니한 컴퓨터
      바이러스 등의 유포나 기타 운영자가 통제할 수 없는 불가항력적 사유로 인한
      회원의 손해에 대하여 책임지지 않습니다.
    </Body>
  </View>
);
