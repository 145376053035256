import React from "react";
import styled from "styled-components";

const View = styled.div`
  flex: 1;
`;
const Head = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 14px;
  margin-top: 30px;
`;
const Body = styled.span`
  line-height: 28px;
  font-size: 16px;
  text-align: justify;
  letter-spacing: 0.8px;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <View>
    <Head>제 1조(개인정보의 처리목적) </Head>
    <Body>
      회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
      개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
      변경되는 경우에는 개인정보 보호법 18조에 따라 별도의 동의를 받는 등 필요한
      조치를 이행 할 예정입니다.
      <br />
      <br />
      1. 회원가입 및 관리
      <br />
      회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별인증, 회원자격
      유지관리, 제한적 본인 확인 재 시행에 따른 본인확인, 서비스 부정이용 방지,
      만 14세 미만 아동의 개인정보 처리시 법정 대리인의 동의 여부를 확인, 각종
      고지,통지,고충처리 등을 목적으로 개인정보를 처리합니다.
      <br />
      2. 재화 또는 서비스 제공
      <br />
      물품배송, 서비스제공, 계약서 청구서 발송, 콘텐츠
      제공,맞춤서비스제공,본인인증,연령인증,요금결제정산,채권추심 등을 목적으로
      개인정보를 처리합니다.
      <br />
      3. 고충처리
      <br />
      민원인의 신원확인,민원사항 확인,사실조사를 위한 연락 통지,처리결과 통보
      등의 목적으로 개인정보를 처리합니다.
      <br />
    </Body>
    <Head>제 2조(개인정보의 처리 및 보유기간)</Head>
    <Body>
      ① 회사는 법령에 따른 개인정보 보유 이용기간 또는 정보주체로부터 개인정보를
      수집시에 동의 받은 개인정보 보유 이용기간 내에서 개인정보를 처리
      보유합니다.
      <br />
      ② 각각의 개인정보 처리 및 보유기간은 다음과 같습니다.
      <br />
      1. 회원 가입 및 관리 : 사업자/단체 탈퇴시까지 다만,다음의 사유에 해당하는
      경우에는 해당사유 종료시까지
      <br />
      1) 관계법령 위반에 따른 수사 조사 등이 진행중인 경우에는 해당 수사 조사
      종료시까지
      <br />
      2) 서비스 이용에 따른 채권 채무관계 잔존시에는 해당 채권 채무관계 정산 시
      까지
      <br />
      2. 재화 또는 서비스 제공 : 재화 서비스 공급완료 및 요금결제 정산
      완료시까지
      <br />
      다만, 다음의 사유에 해당하는 경우에는 해당기간 종료시까지
      <br />
      1) [전자상거래 등에서의 소비자 보호에 관한 법률]에 따른 표시 광고,계약내용
      및 이행 등 거래에 관한 기록
      <br />
      -표시 광고에 관한 기록 : 6개월
      <br />
      -계약 또는 청약철회,대금결제, 재화 등의 공급기록 : 5년
      <br />
      -소비자 불만 또는 분쟁처리에 관한 기록 : 3년
      <br />
      2) [통신비밀보호법] 제 41조에 따른 통신사실 확인 자료 보관
      <br />
      -가입자 전기통신일시, 개시 종료시간, 상대방 가입자번호,사용도수,발신기지국
      위치추적자료 : 1년
      <br />
      -컴퓨터 통신,인터넷 로그기록자료, 접속지 추적자료 : 3개월
      <br />
    </Body>
    <Head>제 3조(개인정보의 제 3자 제공)</Head>
    <Body>
      ① 회사는 정부주체의 개인정보를 제 1조 (개인정보의 처리 목적)에서 명시한
      범위내에서만 처리하며,정보주체의 동의,법률의 특별한 규정 등 개인정보
      보호법 제 17조에 해당하는 경우에만 개인정보를 제 3자에게 제공합니다.
      <br />
      ② 회사는 다음과 같이 개인정보를 제 3자에게 제공하고 있습니다.
      <br />
      -개인정보를 제공받는 자 : 없음
      <br />
      -제공받는 자의 개인정보 이용목적 : 이벤트 공동개최 등 업무제휴 및 제휴
      신용 카드발급
      <br />
      -제공하는 개인정보 항목 : 성명, 주소, 전화번호, 이메일 주소
      <br />
      -제공받는 자의 보유 이용기간 : 계약에 따른 거래기간 동안
      <br />
    </Body>
    <Head>제 4조(개인정보처리의 위탁)</Head>
    <Body>
      ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를
      위탁할수 있으나 현재는 직접 관리 합니다.
      <br />
      1. 전화상담센터운영
      <br />
      -위탁 받는 자 (수탁자) : 추후 선정
      <br />
      -위탁하는 업무의 내용 : 전화상담 응대, 부서 및 직원 안내 등<br />
    </Body>
    <Head>2. A/S센터 운영</Head>
    <Body>
      -위탁 받는 자 (수탁자) : 추후 선정
      <br />
      -위탁하는 업무의 내용 : 고객 대상 제품 A/S 제공
      <br />
      ②회사는 위탁계약 체결 시 개인정보 보호법 제 25조에 따라 위탁업무 수행목적
      외 개인정보 처리금지, 기술적 관리적 보호조치,재 위탁 제한, 수탁자에 대한
      관리감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고,
      수탁자가 개인정보를 안전하게 처리 하는지를 감독하고 있습니다.
      <br />
      ③위탁 업무의 내용이나 수탁자가 변경 될 경우에는 지체없이 본 개인정보
      처리방침을 통하여 공개하도록 하겠습니다.
      <br />
    </Body>
    <Head>제 5조(정보주체의 권리 의무 및 행사 방법)</Head>
    <Body>
      ①정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를
      행사할 수 있습니다.
      <br />
      1. 개인정보 열람요구
      <br />
      2. 오류 등이 있을 경우 정정 요구
      <br />
      3. 삭제 요구
      <br />
      4. 처리정지 요구
      <br />
      ② 제 1항에 따른 권리 행사는 회사에 대해 서면,전화,전자우편,모사전송(FAX)
      등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
      <br />
      ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는
      회사는 정정 또는 삭제를 완료할 때 가지 당해 개인정보를 이용하거나 제공하지
      않습니다.
      <br />
      ④ 제 1항에 따른 권리행사는 정보주체의 법정대리인이나 위임을 받은 자 등
      대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제
      11호 서식에 따른 위임장을 제출하셔야 합니다.
      <br />
      ⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는
      정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
      <br />
    </Body>
    <Head>제 6조(처리하는 개인정보 항목)</Head>
    <Body>
      회사는 다음의 개인정보 항목을 처리하고 있습니다.
      <br />
      1. 서비스 회원 가입 및 관리
      <br />
      필수 항목:이메일 주소,비밀번호,닉네임,휴대전화번호,차량번호
      <br />
      2. 재화 또는 서비스 제공
      <br />
      필수 항목 : 이메일 주소,비밀번호,닉네임,휴대전화번호,주소,차량번호
      <br />
      3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성 되어
      수집될 수 있습니다.
      <br />
      IP주소,쿠키,MAC주소,서비스이용기록,방문기록,불량이용기록 등<br />
    </Body>
    <Head>제 7조(개인정보의 파기)</Head>
    <Body>
      ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게
      되었을 때에는 지체없이 해당 개인정보를 파기합니다.
      <br />
      ② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이
      달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존 하여야 하는
      경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
      달리 하여 보존합니다.
      <br />
      ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
      <br />
      1. 파기절차
      <br />
      회사는 파기 사유가 발생한 개인정보를 성정하고, 회사의 개인정보 보호
      책임자의 승인을 받아 개인정보를 파기합니다.
      <br />
      2. 파기방법
      <br />
      회사는 전자적 파일 형태로 기록,저장된 개인정보는 기록을 재생할 수 없도록
      로우 레벨 포맷 등의 방법을 이용하여 파기하며, 종이문서에 기록 저장된
      개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
      <br />
    </Body>
    <Head>제 8조(개인정보의 안전성조 확보 조치)</Head>
    <Body>
      회사는 개인정보의 안정성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
      <br />
      <br />
      1. 관리적 조치 : 내부관리계획 수립 시행, 정기적 직원 교육 등<br />
      2. 기술적 조치 : 개인정보 처리시스템 등의 접근권한 관리, 접근 통제시스템
      설치, 고유 식별정보 등의 암호화, 보안프로그램 설치
      <br />
      3. 물리적 조치 : 전산실, 자료보관실 등의 접근 통제
      <br />
    </Body>
    <Head>제 9조(개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항)</Head>
    <Body>
      ① 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를
      저장하고 수시로 불러오는 ‘쿠키’ 를 사용합니다.
      <br />
      ② 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터
      브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에
      저장되기도 합니다.
      <br />
      가. 쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문
      및 이용형태, 인기 검색어,보안접속 여부, 등을 파악하여 이용자에게 최적화된
      정보 제공을 위해 사용됩니다.
      <br />
      나. 쿠키의 설치 운영 및 거부 : 웹 브라우저 상단의 도구{">"}인터넷옵션{">"}
      개인정보 메뉴의 옵션설정을 통해 쿠키의 저장을 거부 할 수 있습니다.
      <br />
      다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
      있습니다.
      <br />
    </Body>
    <Head>제 10조(개인정보 보호책임자)</Head>
    <Body>
      ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와
      관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
      보호 책임자를 지정하고 있습니다.
      <br />
      {">"}개인정보 보호 책임자
      <br />
      성명 : 신희선
      <br />
      직책 : 차장
      <br />
      연락처 : 1644-8318
      <br />
      ※개인정보 보호 담당부서로 연결 됩니다.
      <br />
      {">"}개인정보 보호 담당부서
      <br />
      부서명 : 관리팀
      <br />
      담당자 : 신희선
      <br />
      연락처 : 1644-8318
      <br />
      ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든
      개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
      보호책임자 및 담당부서로 문의하실 수 잇습니다. 회사는 정보주체의 문의에
      대해 지체없이 답변 및 처리해드릴 것입니다.
      <br />
    </Body>
    <Head>제 11조(개인정보 열람청구)</Head>
    <Body>
      정보주체는 개인정보 보호법 제 35조에 따른 개인정보의 열람 청구를 아래의
      부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게
      처리되도록 노력 하겠습니다. 운영자는 회원이 미풍양속에 저해되거나
      국가안보에 위배되는 게시물 등 위법한 게시물을 등록 • 배포할 경우 관련
      기관의 요청이 있을 시 회원의 자료를 열람 및 해당 자료를 관련 기관에 제출할
      수 있습니다.
      <br />
      <br />
      {">"}개인정보 열람청구 접수 처리부서
      <br />
      부서명 : 관리팀
      <br />
      담당자 : 신희선
      <br />
      연락처 : 1644-8318
      <br />
    </Body>
    <Head>제 12조(권익침해 구제방법)</Head>
    <Body>
      정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제,상담 등을 문의
      하실 수 있습니다.
      <br />
      아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보
      불만처리, 피해 구제 결과에 만족하지 못하시거나 보다 자세한 도움이
      필요하시면 문의하여 주시길 바랍니다.
      <br />
      <br />
      {">"}개인정보 침해신고 센터(한국인터넷진흥원 운영)
      <br />
      -소관업무 : 개인정보 침해사실 신고,상담 신청
      <br />
      -홈페이지 : privacy.kisa.or.kr
      <br />
      -전화 : (국번없이)118
      <br />
      -주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
      개인정보침해신고센터
      <br />
      {">"} 개인정보 분쟁조정위원회
      <br />
      -소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정(민사적해결)
      <br />
      -홈페이지 : www.kopico.go.kr
      <br />
      -전화 : (국번없이) 1833-6972
      <br />
      -주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층
      <br />
      {">"}대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
      <br />
      {">"}경찰청 사이버 안전국 : 182 (http://cyberbureau.police.go.kr)
      <br />
    </Body>
    <Head>제 13조(영상정보처리기기 설치 운영)</Head>
    <Body>
      ① (주)시큐릿은 아래와 같이 영상 정보처리기기를 설치 운영 하고 있습니다,
      <br />
      1. 영상정보처리기기 설치근거 목적 : (주)시큐릿의 시설안전 화재예방
      <br />
      2. 설치대수, 설치위치,촬영범위 : 사옥 로비 전시실 등 주요시설물에 ○○대
      설치, 쵤영범위는 주요시설물의 전공간을 촬영
      <br />
      3. 관리책임자,담당부서 및 영상정보에 대한 접근권한자 : 관리팀 신희선 차장
      <br />
      4, 영상정보 촬영 시간, 보관기간, 보관장소, 처리방법
      <br />
      -촬영시간 : 24시간 촬영
      <br />
      -보관기간 : 촬영시부터 30일
      <br />
      -보관장소 및 처리방법 : 관리팀 영상정보처리기기 통제실에 보관 처리
      <br />
      5. 영상정보 확인 방법 및 장소 : 관리책임자에 요구
      <br />
      6. 정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람
      전재확인 청구서로 신청 해야 하며, 정보주체 자신이 촬영된 경우 또는 명백히
      정보주체의 생명 신체재산 이익을 위해 필요한 경우에 한해 열람을 허용함
      <br />
      7. 영상정보 보호를 위한 기술적 관리적 물리적 조치 : 내부 관리 계획 수립,
      접근 통제 및 접근권한 제한, 영상정보의 안전한 저장 전송기술 적용, 처리기록
      보관 및 위 변조 방지조치, 보관시설 마련 및 잠금장치 설치 등<br />
    </Body>
    <Head>제 14조(개인정보 처리방침 변경)</Head>
    <Body>① 이 개인정보 처리 방침은 2023.03.01.부터 적용 됩니다.</Body>
  </View>
);
