import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import LocationContents from "../components/Tests/LocationContents";
import MarketingContents from "../components/Tests/MarketingContents";
import ServiceContents from "../components/Tests/ServiceContents";

const View = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

function Service() {
  return (
    <View>
      <Header title="이용약관" />
      <ServiceContents />
      <br />
      <br />

      <Header title="위치 정보 이용약관" />
      <LocationContents />
      <br />
      <br />

      <Header title="마케팅" />
      <MarketingContents />
    </View>
  );
}

export default React.memo(Service);
